/*global $,jQuery,Class */
var $ = jQuery;
function log(){
    return;  //debug
   console.log(arguments);
}
function debug(){
    return; //debug
    console.debug(arguments);
}
var IRONISTIC_OAAA = IRONISTIC_OAAA || {};
IRONISTIC_OAAA.Consts = {
	BASE_API_URL: '/api',
    IMAGES_SCENES_LARGE: '/images/assets/scene-large/',
    IMAGES_SCENES_SMALL: '/images/assets/scene-small/',
    IMAGES_BILLBOARDS_LARGE: '/images/assets/billboard-large/',
    IMAGES_BILLBOARDS_PREVIEW: '/images/assets/billboard-preview/',
    IMAGES_BILLBOARDS_SMALL: '/images/assets/billboard-small/',
	PROCESSING_DELAY: 200,
	INVALID_STATUS: 422
};
/******************* MODELS *********************/
IRONISTIC_OAAA.Model = Class.$extend({
	__init__: function(json){
		$.extend(this, json);
		return this;
	}
});
///Scene Model ////////////////////////////////////////////////////////////////////////////////
IRONISTIC_OAAA.ScenesModel = IRONISTIC_OAAA.Model.$extend({
	__init__: function(json) {
		this.$super( json );
		this.data = json;
		return this;
	}
});
IRONISTIC_OAAA.SceneModel = IRONISTIC_OAAA.Model.$extend({
	id: undefined,
	title: undefined,
	format_id: undefined,
	subformat_id: undefined,
	location_id: undefined,
	type_id: undefined,
	image: undefined,
	dimensions: undefined,
	plot1: undefined,
	plot2: undefined,
	plot3: undefined,
	plot4: undefined,
	plot5: undefined,
	plot6: undefined,
	zoom: undefined,
	description: undefined,
	list:undefined,
	idLabels: [], //{label: undefined, id: null }
	__init__: function(json) {
        json.images = $.parseJSON(json.images);
		this.$super(json);
		return this;
	}
});
IRONISTIC_OAAA.ProjectModel = IRONISTIC_OAAA.Model.$extend({
	id:undefined,
    scene_id:undefined,
    name:undefined,
    description:undefined,
    created_at:undefined,
    updated_at:undefined,
    expires:undefined,
    active:undefined,
	idLabels: [], //{label: undefined, id: null }
	__init__: function(json) {
		this.$super(json);
		return this;
	}
});
IRONISTIC_OAAA.ProjectBillboardModel = IRONISTIC_OAAA.Model.$extend({
	id:undefined,
        project_id:undefined,
        plot:undefined,
        image:undefined,
        dimensions:undefined,
        upperleft:undefined,
        upperright:undefined,
        lowerleft:undefined,
        lowerright:undefined,
        extension:undefined,
        sort_order:undefined,
        created_at:undefined,
        updated_at:undefined,
        active:undefined,
	idLabels: [], //{label: undefined, id: null }
	__init__: function(json) {
        if(!json.images.large){
            json.images = $.parseJSON( json.images );
        }
        this.$super(json);

		return this;
	}
});
/********* VIEWS     ******************************/
///Base Class///////////////////////////////////////////////////////////////////////////////////////
IRONISTIC_OAAA.Base = Class.$extend({
	trigger: function(eventName, data) {
		$(this).trigger(eventName, data);
	}
});
IRONISTIC_OAAA.View = IRONISTIC_OAAA.Base.$extend({
	id: undefined,
	$canvas: undefined, //element
	defaults: undefined,
	__init__: function(defaults) {
		this.defaults = $.extend(this.defaults, defaults);
		log("IRONISTIC_OAAA.View loaded!");
	}
});
IRONISTIC_OAAA.PROJECT_VIEW = IRONISTIC_OAAA.View.$extend({

    Events: {
        SCENE_LOADED: "sceneLoaded",
        CANVAS_LOADED: "projectCanvasLoaded",
        CANVAS_DRAWN: "projectCanvasDrawn",
        BILLBOARD_LOADED: "billboardLoaded",
        BILLBOARD_LOADED_ALL: "allBillboardLoaded",
        BILLBOARD_LOADED_FIRST_DRAWN: "EveryPlotFirstBillboardDrawn",
        BILLBOARD_DRAWN: "billboardDrawn",
        PNG_DRAWN: "imageDrawn",
        BILLBOARD_DIGITAL_STARTED: "slideshowstarted",
        BILLBOARD_DIGITAL_ZOOM: "slideshowzoom"
    },

	project: undefined,
    scene: undefined,
    billboards: undefined,
	defaults: undefined,
    img_path_scene: undefined, //load from const as default
    img_path_billboard: undefined,
    $canvas:undefined, //<canvas>
    $canvasWrap: undefined, //<div id='wrapper'> canvas </div>
    copyCanvas: undefined, //use this to replicate for slideshows
    queueBillboardImages: undefined,
    slideShowEnabled: false,
    slideShowSeconds: 5000,
    sliderIndex: 0,

    specs: {"x":0,"y":0,"w":0,"h":0,"index":0}, //canvas {x:0,y:0,width:2500,height:600}

	__init__: function(defaults) {
                this.id = defaults.id || undefined;
                this.scene = (typeof defaults.scene != 'undefined')?new IRONISTIC_OAAA.SceneModel(defaults.scene):undefined;
                if(defaults.billboards){
                    this.billboards = [];
                    for(x=0;x<defaults.billboards.length;x++){
                        var bb = new IRONISTIC_OAAA.ProjectBillboardModel( defaults.billboards[x] );
                        this.billboards.push(bb);
                    }
                }
                this.img_path_scene = (defaults.scene_image_path)?defaults.scene_image_path:IRONISTIC_OAAA.Consts.IMAGES_SCENES_LARGE;
                this.img_path_billboard = (defaults.billboard_image_path)?defaults.billboard_image_path:IRONISTIC_OAAA.Consts.IMAGES_BILLBOARDS_LARGE;
                this.$canvas = (defaults.$canvas)?defaults.$canvas:undefined;
                this.$canvasWrap = (defaults.$canvasWrap)?defaults.$canvasWrap:undefined;
                this.queueBillboardImages = [];
                this.project = {
                    canvas:{}, ctx:{}, img:{}, ratio:{}
                };
        this.bind(); //lets get the party started;
	},
    bind: function(){
        var _self = this;
        if(typeof _self.$canvasWrap === 'undefined')
            return;



        //load Scene into memory
        //Build Scene and Load/Image/Width/Height
        var proj = this.project;
        proj.canvas = document.createElement("canvas");
        proj.canvas.id = "start";
        proj.ctx = proj.canvas.getContext("2d");
        proj.img = new Image();
        var background = proj.img;
        var strImage = _self.img_path_scene + _self.scene.image;
        log('scene image path => ' + strImage);
        $(background).on(_self.Events.SCENE_LOADED,function()
        {
            log('triggered ! '+_self.Events.SCENE_LOADED);
            _self.plotBillboards();
            $(_self.project.img).off(_self.Events.SCENE_LOADED);
        });
        background.onload = function(){
                proj.canvas.width = background.width;
                proj.canvas.height = background.height;
                _self.project.ctx.drawImage(background, 0, 0,
                                    background.width,    background.height,    // source rectangle
                                        0, 0, background.width, background.height);  // destination rectangle
                _self.project.ctx.save();
                //log('large scene image loaded successfully');
                _self.copyCanvas = _self.cloneMasterCanvas(_self.project.canvas);

        };
        background.src = strImage;

    },
    isFirstBillboardsLoaded: function(){
        var isLoaded = true;
        var bb = this.billboards;
        for(var i=0;i<bb.length;i++){
            try{
                    if(bb[i].file.isloaded == false){
                        return false;
                    }
                }catch(e){
                    return false;
                }
        }
        return isLoaded;
    },
    isFirstBillboardDrawToCanvas: function(){
        //BILLBOARD_LOADED_FIRST_DRAWN
        var isDrawn = true;
        var bb = this.billboards;
        for(var i=0;i<bb.length;i++){
            try{
                    if(bb[i].file.isdrawn == false){
                        return false;
                    }
                }catch(e){
                    return false;
                }
        }
        return isDrawn;
    },
    plotFirstBillboardsInSlots: function (total){
        var _self = this;
        var images = _self.queueBillboardImages;
        $(_self).on(_self.Events.BILLBOARD_LOADED_ALL,function(){
                log('triggered first billboards are now loaded! ');
                $(_self).off(_self.Events.BILLBOARD_LOADED_ALL);
        });
        $(_self).on(_self.Events.BILLBOARD_LOADED_FIRST_DRAWN,function(){
            log('trigger all first billboard drawn to canvas');
            $(_self).off(_self.Events.BILLBOARD_LOADED_FIRST_DRAWN);
            //_self.showCanvas();
        });

        console.warn("plotFirstBillboardsInSlots");
        for(x=0;x<total;x++){
            var board = _self.getBillboardsByPlot(x)[0];
            var image = board.el = new Image();
            //cache
                  //cache
                    board.file = _self.cacheBillboardCanvas();
                    board.file.img = image;
                    board.file.plot = _self.scene['plot'+ (x+1)];
                    board.file.plotDimensions =  _self.scene['plot'+(x+1)+'Dimensions'];
                    board.file.background = _self.showSlideshowSceneCanvas(board.id);
            //load canvas
            image.onload = function(){
                var myboard = oaaaProject.getBillboardsById(this.id);
                myboard.file.isloaded = true;
                if(_self.isFirstBillboardsLoaded()){
                    //log('Thats all folks !!!  all plots are loaded');
                    $(_self).trigger(_self.Events.BILLBOARD_LOADED_ALL );
                }
                _self.drawBillboardToCanvas(_self.project.canvas,board);
            }
            image.id = board.id;
            image.src = board.images.large;
        }
    },
    plotAllBillboardsInSlot: function (boards,slotNumber){
                var _self = this;
                var strPlot = 'plot'+(slotNumber + 1);
                var plotDimensions = _self.scene[strPlot+'Dimensions']; //"plot1Dimensions"
                var scenePlot = _self.scene[strPlot];  //"plot1"
                for(var x=0;x<boards.length;x++){
                    var board = boards[x];
                    var image = board.el = new Image();
                    image.id = board.id;
                    $(image).data('count',x);
                    //cache
                    board.file = _self.cacheBillboardCanvas();
                    board.file.img = image;
                    board.file.plot = scenePlot;
                    board.file.plotDimensions =  plotDimensions;
                    board.file.background = _self.showSlideshowSceneCanvas(board.id);

                    image.onload = function(){
                            var myboard = oaaaProject.getBillboardsById(this.id);
                            myboard.file.isloaded = true;
                            //log("image loaded with id :" +myboard.id );
                            //log("image loaded with filename=>" + myboard.image);
                            //debug( myboard.file.background.canvas );
                            _self.drawBillboardToCanvas(myboard.file.background.canvas,myboard);
                            //log('triggered! ' + _self.Events.BILLBOARD_LOADED);
                            //$(myboard.img).trigger( _self.Events.BILLBOARD_LOADED, myboard );
                            //$(myboard.img).off();
                            log('image completed loading');
                            debug(this);
                            debug(arguments);
                    };
                    image.src = board.images.large;

                        $(image).on(_self.Events.BILLBOARD_DRAWN,function(evt,el){
                            console.warn("show canvas <<<<<<<<<<<");
                            debug( el );
                            //_self.showCanvas();
                            var isFirst = (el.id == _self.billboards[0].id)?true:false;
                            $(el.file.img).off();

                                _self.drawSlideshowImage( el, isFirst );
                                if(isFirst){
                                        _self.slideshowStart();
                                }
                        });



                }//end for x
    },
    plotBillboards: function(){
        log('....plotBillboards called');
            var self = this;
            var totalPlots = self.getScenePlotsCount();
            var scene = this.scene;
            log('....totalPlots ='+totalPlots);
            if( self.slideShowEnabled() ){
                log("slideshow detected");
                for(var i=0;i<totalPlots;i++){
                    var boards = self.getBillboardsByPlot(i); //start at 0
                    self.plotAllBillboardsInSlot(boards,i);
                }//end for i
            } else {
                    self.plotFirstBillboardsInSlots(totalPlots);
            }

    },
    getScenePlotsCount: function(){
        log('....getScenePlotsCount called');
        var count = 0;
        var scene = this.scene;
        for(var i=1;i<=6;i++){
            if((typeof scene['plot'+i] !== 'undefined')&&(scene['plot'+i] != null)){
                count++;
            } else {
                log('break please');
                break;
            }
        }
        return count;
    },
    getBillboardsByPlot: function(plot){
        log('....getBillboardsByPlot called');
        var arr = [];
        var bb = this.billboards;
        var plot = plot || 0; //starts at 0;

        for(var i=0;i<bb.length;i++){
            var board = bb[i];
            if(board.plot == plot){
                arr.push(board);
            }
        }
        return arr;
    },
    getBillboardsById: function(id){
        log('....getBillboardsById called');
        var bb = this.billboards;
        for(var i=0;i<bb.length;i++){
            var board = bb[i];
            if(board.id == id){
                return board;
            }
        }
    },
    showCanvas: function(){
            log('....showCanvas called');
            var canvas = this.project.canvas;

            if(!this.$canvas) {
                var canvasClone = this.$canvas = document.createElement("canvas");

                var el = this.$canvasWrap;
                var scaleWidth = $(el).width();
                var scaleHeight = IRONISTIC_OAAA.Util.calcRatioHeight( canvas.width, canvas.height, scaleWidth );
                this.project.ratio = IRONISTIC_OAAA.Util.calcRatioHeightPercent( this.project.img.width, scaleWidth );
                canvasClone.width = scaleWidth;
                canvasClone.height = scaleHeight;
                el.appendChild(canvasClone);
            }

            this.drawImgClip(0,0,canvas.width,canvas.height);
    },
    drawSlideshowImage: function(board,hide){
        var strId = "slidepng" + board.id;
        var wrapper= $("#image_area");
        var utility = $(".smooth_zoom_preloader");
        var images = $( wrapper ).find("img");

        var self = this;
        if(!board.file.png) {

            board.file.png = new Image();
            board.file.png.onload = function(){
                this.id = strId;
                $("#image_area").append( this );
                //self.$canvasWrap.appendChild( this );
                $( wrapper ).find("img").hide();
                if(!hide){
                    $(this).show();
                }
                this.style.maxWidth = "none";
            }
            board.file.png.src = board.file.background.canvas.toDataURL("image/png");
            return;
        }



        if(utility.length > 0){

              if( $(utility).find("img").length <= 1){
                  //move images into div wrapper zoom
                  $(images).each(function(){
                      if(!$(this).parent().hasClass("smooth_zoom_preloader")){
                          //var el = $(this).detach();
                          //$(el).appendTo(utility);
                         // $(el).insertBefore( self.billboards[0].file.png );
                      }
                  });
              }

        }

        var styleZoomAttr = $(this.billboards[0].file.png).attr("style");


        for(var x = 0;x<this.billboards.length;x++){
            var png = this.billboards[x].file.png;
            $(png).attr("style", styleZoomAttr);
            //$(png).hide();
        }
        if(!hide){
            $("#"+strId).show();
        }


        return (utility.length > 0);  //detect if utility loaded yet
    },
    drawBillboardToCanvas: function(canvas,board) {
                            var self = this;
                            var obj = board.file;
                            var ctx = canvas.getContext("2d");
                            var plotDimensions = obj.plotDimensions;

                            var tmpWidth = plotDimensions.width;
                            var tmpHeight = plotDimensions.height;
                            var isExtension = false;

                            var empty = {};
                            if((board.upperleft != undefined) && (typeof board.upperleft.x  !== 'undefined')){ //check for extension
                                //console.info("extension detected!")
                                tmpWidth = parseFloat(board.lowerright.x) - parseFloat(board.upperleft.x);
                                tmpHeight = parseFloat(board.lowerright.y) - parseFloat(board.upperleft.y);
                                isExtension = true;
                            }

                            obj.img.height = IRONISTIC_OAAA.Util.calcRatioHeight( board.dimensions.width, board.dimensions.height, tmpWidth );
                            obj.img.width = tmpWidth;
                            log('new width', obj.img.width);
                            log('new height', obj.img.height);


                            if(isExtension)
                                    {
                                        log('draw billboard with extension');
                                        ctx.drawImage(obj.img, board.upperleft.x , board.upperleft.y,
                                                    obj.img.width, obj.img.height);  // destination rectangle
                                    } else {
                                        log('draw billboard img standard');
                                        //console.warn("First Image should now be drawn");
                                        ctx.drawImage(obj.img, obj.plot.upperleft.x , obj.plot.upperleft.y,
                                                    obj.img.width, obj.img.height);  // destination rectangle
                                    }


                            obj.isdrawn = true;
                            if(self.isFirstBillboardDrawToCanvas())
                            {
                                $(self).trigger(self.Events.BILLBOARD_LOADED_FIRST_DRAWN);
                            }
                            $(board.file.img).trigger(self.Events.BILLBOARD_DRAWN,board);
                            log('trigger drawn billboard ' + board.image);
    },
    drawImgClip: function(x,y,w,h,sliderCanvas){
        log('....drawImgClip called');
        log("parameters:{'x':"+x+",'y':"+y+",'w':"+w+",'h':"+h+"}" );
        this.specs.x= x;
        this.specs.y= y;
        this.specs.w= w;
        this.specs.h= h;
        var canvas = sliderCanvas || this.project.canvas;
        var canvasClone = this.$canvas;
        var cloneCtx = canvasClone.getContext("2d");
            cloneCtx.drawImage(

                // use "sourceImage"
                // or our canvas since we already loaded there

                // clip a 640x512 portion of the source image at left-top = [1000,500]
                //sourceImage,1000,500,640,512,
                canvas,x,y,w,h,

                // draw the 640x512 clipped subimage at 0,0 on the canvas
                0,0,canvasClone.width,canvasClone.height
            );
        this.trigger(this.Events.CANVAS_DRAWN);
    },
    getImgURL: function(){
        //return this.$canvas.toDataURL("image/png");
        return this.project.canvas.toDataURL("image/png");
    },
    //Take the available width and original image; whatever difference zoom and clip!
    zoomOrig: function () { //Alternative
        log('....zoomOrig called');
            //22% is original zoom just to fit on canvas;
            //lets show original canvas;
            var start = { x:0, y:0,
                         w:this.project.img.width, h:this.project.img.height,
                         cx:this.project.canvas.width/2, cy:this.project.canvas.height/2 //center coordinates
                        };
            var ratio = this.project.ratio;
            //move move to 22% over and clip;
            start.totalX = start.cx - start.x; //total X plots to center;
            start.totalY = start.cy - start.y; //total Y plots to center;
            var x = start.x + (start.totalX * (ratio/100));
            var y = start.y + (start.totalY * (ratio/100));
            log("scale:",ratio);
            log("coordinate x:"+x);
            log("coordinate y:"+y);
            log("total x:"+start.totalX);
            log("total y:"+start.totalY);

            this.drawImgClip(x,y, start.w-x,start.h-y);
    },
    zoom: function (n){
            var scale = (n || 10) / 100;
            var ratio = this.project.ratio
            scale += (ratio/100);//adjustment
            //lets show original canvas;
            var start = { x:0, y:0, w:this.project.canvas.width, h:this.project.canvas.height, cx:this.project.canvas.width/2, cy:this.project.canvas.height/2 };
            //move move to n over and clip;
            start.totalX = start.cx - start.x; //total X plots to center;
            start.totalY = start.cy - start.y; //total Y plots to center;
            var x = start.x + (start.totalX * (scale));
            var y = start.y + (start.totalY * (scale));
            log("scale:",scale);
            log("coordinate x:"+x);
            log("coordinate y:"+y);
            log("total x:"+start.totalX);
            log("total y:"+start.totalY);
            var newWidth = start.w-x;
            newWidth =newWidth-(newWidth * scale); //adjustment;
            var clippedHeight = IRONISTIC_OAAA.Util.calcRatioHeight(start.w,start.h,newWidth);
            log('new dimensions ' + newWidth,clippedHeight);
            this.drawImgClip(x,y, newWidth,clippedHeight);
    },
    showSlideshowSceneCanvas: function (boardid){
        log("boardid  = " + boardid);
        var $this = this;
        var proj = {};
        //proj.canvas = $this.cloneCanvas($this.copyCanvas);
        proj.canvas = this.cloneCanvas();
        proj.canvas.id = boardid;
        proj.ctx = proj.canvas.getContext("2d");
        //$this.queueBillboardImages['id'+boardid].background = proj;
        proj.canvas.style.display = "none";
        $(this.$canvasWrap).append(proj.canvas);
        return proj;
    },
    cloneMasterCanvas: function (){
        var canvas = this.cloneCanvas();
        $(this.project.img).trigger(this.Events.SCENE_LOADED, [true]);
        return canvas;
    },
    cloneCanvas: function (el){
        var oldCanvas = el || this.project.canvas;
        //create a new canvas
        var newCanvas = document.createElement('canvas');
        var context = newCanvas.getContext('2d');
        //set dimensions
        newCanvas.width = oldCanvas.width;
        newCanvas.height = oldCanvas.height;
        //apply the old canvas to the new one
        context.drawImage(oldCanvas, 0, 0);
        return newCanvas;
    },
    cacheBillboardCanvas: function(){ //x is index from loop
        return {
            'img':{},
            'plot': {},
            'plotDimensions': {},
            'isloaded':false,
            'isdrawn':false,
            'background': {}
        };
    },
    slideShowEnabled: function () {
        if(this.billboards.length <= 1)
            return false;

        if(this.billboards.length >= 2){
            if((this.billboards[0].plot == 0) && (this.billboards[1].plot == 0))
                return true;
        }
        return false;
    },
    slideshowStart: function (){
        if(!this.slideShowEnabled())
            return;

        var self = this;
        this.sliderIndex = 0;
        this.slideshowTimer = setInterval(function(){
            log('slideshow called');
            oaaaProject.slideshowNext();

            }, self.slideShowSeconds);
        $(this).trigger(this.Events.BILLBOARD_DIGITAL_STARTED,this.billboards[0]);
    },
    slideshowEnd: function (){
        clearTimeout(this.slideshowTimer);
        log("slideshowEnd");
    },
    slideshowNext: function (){
        log("next Billboard please");
        if(typeof this.specs.index === 'undefined'){
            this.specs.index = 0;
        }
        var specs = this.specs;
        specs.index++;
        if(specs.index >= this.billboards.length){
            specs.index = 0; //start over
        }
        var board = this.billboards[specs.index];
        var obj = board.file;

        //check if image loaded
        if(obj.isloaded == false){
            log('billboard image never loaded so skip slideshow image');
            while( specs.index < this.billboards.length){
                var b = this.billboards[specs.index];
                if(b.file.isloaded){
                    board = b;
                    obj = b.file;
                    break;
                }
                specs.index++;
            }
        }


        this.drawSlideshowImage(board);



        //this.specs = {'x': x ,'y': y ,'w': w , h: h };
        //turn off if we are using images instead of canvas
        //this.drawImgClip( specs.x, specs.y, specs.w, specs.h, obj.background.canvas  );

    }
});
IRONISTIC_OAAA.CUSTOM_PROJECT_VIEW = IRONISTIC_OAAA.PROJECT_VIEW.$extend({

    Events: {
        PROJECT_SAVED: "projectSaved",
        PROJECT_NEXT: "projectNextStep",
        BILLBOARD_SAVE_SUCCESS: "savebillboardsSuccessful",
        BILLBOARD_SAVE_FAIL: "savebillboardsFailed",
        FILE_DRAWN: "filedrawntocanvas",
        FILE_DRAWN_COMPLETE: "allfilesdrawntocanvas",
    },

	scenes: undefined,
	billboards: undefined,
    thumbnailsEl: undefined, //
    thumbnails:[],
	defaults: undefined,
    files: undefined,
    canvasArrSlideshow: undefined,
    mergedArr: undefined,
	__init__: function(defaults) {
		this.defaults = {
			id:''
		}
        this.scenes = defaults.scenes || [];
        this.thumbnailsEl = defaults.thumbnailsEl || undefined;
		this.defaults = $.extend(this.defaults, defaults);
        this.canvasArrSlideshow = [];
		log("IRONISTIC_OAAA.CUSTOM_PROJECT_VIEW loaded!");
		if(this.scenes){
			log('scenes loaded ', this.scenes.length);
			for(var i=0;i<this.scenes.length;i++){
				var scene = this.scenes[i];
				if(typeof scene.images === 'string') {
					scene.images = JSON.parse(scene.images);
				}
			}
		}
        this.billboards = defaults.billboards || [];
        this.scene = defaults.scene || undefined;
        this.bind();
	},
    unbind: function(){
        $(this).off([this.Events.PROJECT_SAVED,this.Events.PROJECT_NEXT]);
        $(this).off([this.Events.BILLBOARD_SAVE_SUCCESS,this.Events.BILLBOARD_SAVE_FAIL]);
    },
    bind: function(){
        var that = this;
        /** MOVED MERGING TO SERVER
        $(this).on(this.Events.PROJECT_SAVED,function(evt,model){
            log("My project saved clicked");
            debug(evt);
            var files = [];
            var empty = {};
            var totalPlots = that.getScenePlotsCount();
            var isSlideshow = that.scene.slideshow;
            if( isSlideshow ){
                log("no merging please");
                that.createMergedCanvasFromFiles(
                        billboardFullWidth);
            } else {
                //combine
                if((typeof billboardDropzone1 !== 'undefined') && (typeof billboardDropzone2 !== 'undefined') && (billboardFullWidth.files.length == 0)){
                    if(  typeof billboardDropzone3 !== 'undefined' ){
                      log('merge three slots of files');
                       that.createMergedCanvasFromFiles(
                        billboardDropzone1, //slot1
                        billboardDropzone2 , //slot2
                        billboardDropzone3 ); //slot3
                    } else {
                        log('merge two slots of files');
                      that.createMergedCanvasFromFiles(
                        billboardDropzone1, //slot1
                        billboardDropzone2  //slot2
                        );
                    }
                } else {
                    that.createMergedCanvasFromFiles(
                        billboardFullWidth);
                }
            }
        });
        ***/
        $(this).on(this.Events.BILLBOARD_SAVE_SUCCESS,function(evt,model){
            log("Billboard saved success");
        });
        $(this).on(this.Events.BILLBOARD_SAVE_FAIL,function(evt,model){
            log("Billboard saved fail");
        });

        $(this).on(this.Events.PROJECT_NEXT,function(){
            log("load canvas");
            if(that.scene){
                        that.canvas = document.createElement("canvas");
                        that.canvas.id = "start";
                        that.ctx = that.canvas.getContext("2d");
                        var scene = that.scene;
                        var background = that.img = new Image();
                        var strImage = scene.images.large;
                        log('scene image path => ' + strImage);
                        background.onload = function(){
                                var scene = that.scene.dimensions;
                                that.canvas.width = background.width;
                                that.canvas.height = background.height;
                                that.ctx.drawImage(background, 0, 0,
                                                    background.width,    background.height,    // source rectangle
                                                        0, 0, background.width, background.height);  // destination rectangle

                            /***
                            that.canvas.width = scene.width;
                            that.canvas.height = scene.height;
                                that.ctx.drawImage(background, 0, 0,
                                                    background.width,    background.height,    // source rectangle
                                                        0, 0, scene.width, scene.height);  // destination rectangle
                            ***/
                                that.ctx.save();
                                //log('large scene image loaded successfully');
                                that.copyCanvas = IRONISTIC_OAAA.Util.cloneCanvas(that.canvas);
                                log("scene loaded to canvas");
                        };
                        background.src = strImage;

            }
        });

        $(this).on(that.Events.FILE_DRAWN,function(evt,data){
            log('check if we have all looaded to canvas; then upload');
            var totalPlots = that.getScenePlotsCount();
            var files = that.files;
            var isAllLoaded = false;
            for(var i=1;i<=totalPlots;i++){
                var strSlot = 'slot' + i;
                var list = this.files[strSlot];
                for(var x=0;x<list.length;x++){
                    var file = list[x];
                    log(file.id);
                    if(typeof file.isdrawn === 'undefined')
                        return;
                    if( file.isdrawn == false)
                        return;
                }

            }
            log('all files have been drawn');
            $(that).trigger(that.Events.FILE_DRAWN_COMPLETE,this.files);
        });
    },
    displayThumbnails: function(scenesList){
        $(this.thumbnailsEl).html("");
        for(var i=0;i<scenesList.length;i++){
            var scene = scenesList[i];
            this.displayThumb(scene);
        }
    },
    displayThumb: function(scene){
        var link = document.createElement("a");
        $(link).addClass("oaaa-block-img col-item");
        var img = document.createElement("img");
        img.src = scene.images.thumbnail;
        img.width = "180";
        img.height = "102";
        var span = document.createElement("span");
        $(span).addClass("e-title");
        span.appendChild(document.createTextNode( scene.title ));
        link.appendChild(img);
        link.appendChild(span);
        $(link).data('id',scene.id);
        $(this.thumbnailsEl).append(link);
                //this.thumbnails.push(link);
    },
	getAllScenesJson: function() {
					var $this = this;
	            	var apiProxy = new IRONISTIC_OAAA.ApiProxy();
		            $(apiProxy).on(apiProxy.Events.API_SUCCESS, function(e, model){
		                $(apiProxy).off();
		                debug(model);
		                $this.scenes = model;
		                log(' saved successfully.');
		            });
		            $(apiProxy).on(apiProxy.Events.API_ERROR, function(e, model){
		                $(apiProxy).off();
		                log('oops');
		                IRONISTIC_OAAA.Prompt.msg( e );
		            });
		            var params = {
		                /** id: 1001,
		                active: colorId **/
		            }
		            apiProxy.get('/scene', IRONISTIC_OAAA.ScenesModel,params);

	},
    getSceneById: function(id){
		if(typeof this.scenes === 'object'){
			for(var k in this.scenes){
				var obj = this.scenes[k];
				if(obj.id == id){
					return obj;
				}
			}
		}
        for(var i=0;i<this.scenes.length;i++){
            var scene = this.scenes[i];
            if(scene.id == id){
                return scene;
            }
        }
        return;
    },
    getSceneDimensionsBySlot: function(n){
           return {
               coordinates: this.scene["plot"+n] || this.scene["plot1"],
               dimensions:  this.scene["plot"+n+"Dimensions"] || this.scene["plot1Dimensions"]
           };
    },
    getSceneByFormat: function(formatId){
        var list = [];
        for(var i=0;i<this.scenes.length;i++){
            var scene = this.scenes[i];
            if(scene.format_id == formatId){
                list.push(scene);
            }
        }
        return list;
    },
    getFileBySlotId: function(slot,id){
        var files = this.files['slot'+slot];
        for(var i=0;i<files.length;i++){
            if(files[i].id == id){
                return files[i];
            }
        }
    },
    optimizeCanvas: function(canvas){
        var newWidth = this.scene.dimensions.width;
        log("scene dimensions " + this.scene.dimensions.width + " / " + this.scene.dimensions.height);
        log("canvas current width/height " + canvas.width + " / " + canvas.height);

        //var newHeight = IRONISTIC_OAAA.Util.calcRatioHeight(canvas.width,canvas.height,newWidth);
        var newHeight = this.scene.dimensions.height;
            var newCanvas = document.createElement('canvas');
            var context = newCanvas.getContext('2d');
            //set dimensions
            newCanvas.width = newWidth;
            newCanvas.height = newHeight;
            log("optimized canvas  width/height " + newCanvas.width + " / " + newCanvas.height);
            //apply the old canvas to the new one
            context.drawImage(canvas,
                              0, 0, canvas.width,canvas.height,
                              0, 0, newWidth, newHeight);
        return newCanvas;
    },
    drawFileToCanvas: function(canvas,file,board) {
                            var self = this;
                            var ctx = canvas.getContext("2d");
                            var plot = this.getSceneDimensionsBySlot( file.slot );
                            var img = file.image;

                            var tmpWidth = plot.dimensions.width;
                            var tmpHeight = plot.dimensions.height;
                            var isExtension = false;

                            var empty = {};
                            if((file.upperleft != undefined) && (typeof file.upperleft.x  !== 'undefined')){ //check for extension
                                //console.info("extension detected!")
                                tmpWidth = parseFloat(file.lowerright.x) - parseFloat(file.upperleft.x);
                                tmpHeight = parseFloat(file.lowerright.y) - parseFloat(file.upperleft.y);
                                isExtension = true;
                            }

                            img.height = IRONISTIC_OAAA.Util.calcRatioHeight( file.file.width, file.file.height, tmpWidth );
                            img.width = tmpWidth;
                            log('new width', img.width);
                            log('new height', img.height);


                            if(isExtension)
                                    {
                                        log('draw billboard with extension');
                                        ctx.drawImage(img, file.upperleft.x , file.upperleft.y,
                                                    img.width, img.height);  // destination rectangle
                            } else {
                                        log('draw billboard img standard');
                                        //console.warn("First Image should now be drawn");
                                        ctx.drawImage(img, plot.coordinates.upperleft.x , plot.coordinates.upperleft.y,
                                                    img.width, img.height);  // destination rectangle
                            }


                            file.isdrawn = true;
                            $(self).trigger(self.Events.FILE_DRAWN,file);

    },
    createMergedCanvasFromFiles: function(slot1,slot2,slot3){
        this.files = {};
        var _self = this;
        for(var x=0;x<arguments.length;x++){
            var files = arguments[x].files || [];
            log("do we have a slide show ?  this number would be greater than 1? " +files.length );
            for(var i=0;i<files.length;i++){
                    var file = files[i];
                    var strSlotIndex = 'slot'+(x+1);
                    if( typeof this.files[ strSlotIndex ] === 'undefined'){
                        this.files[ strSlotIndex ] = [];
                    }
                    log('cycling...'+i);
                    if(file.accepted == true && file.status == 'success'){
                        log('cycling...file validated');
                            var obj = $.parseJSON(file.xhr.response);
                            if(obj.is_uploaded){
                                log('cycling...file uploaded success');
                                var b = {'file':file,'json': obj, "image": new Image(), "id": "s"+x+"_"+i, "slot":(x+1),"isloaded":false };
                                this.files[ strSlotIndex ].push( b );
                                b.image.onload = function(){
                                    var file = _self.getFileBySlotId( $(this).data('slot'), this.id);
                                    file.isloaded = true;
                                    /***
                                    if(_self.isFirstBillboardsLoaded()){
                                        //log('Thats all folks !!!  all plots are loaded');
                                        $(_self).trigger(_self.Events.BILLBOARD_LOADED_ALL );
                                    }
                                    ***/
                                    log("files length confirmed" + files.length);
                                    if(files.length > 1){ //slideshow
                                        log("clone slide show canvas")
                                        var canvasNew = IRONISTIC_OAAA.Util.cloneCanvas(_self.canvas);
                                        _self.canvasArrSlideshow.push( canvasNew );
                                        _self.drawFileToCanvas(canvasNew,file);
                                    } else {
                                        _self.drawFileToCanvas(_self.canvas,file);
                                    }
                                    log('image loaded');
                                }
                                b.image.id = b.id;
                                //b.image.data-slot = b.slot;
                                $(b.image).data('slot',b.slot);
                                b.image.src = b.json.src;
                            }
                    }
            }

        }



    }

});
/*** Merged Scene ***/
IRONISTIC_OAAA.MERGED_VIEW = IRONISTIC_OAAA.CUSTOM_PROJECT_VIEW.$extend({
    Events: {
        PROJECT_INIT: "projectInitialized",
        SLIDESHOW_START: "slideshowstarted",
        SLIDESHOW_PAUSE: "slideshowpaused",
        SLIDESHOW_END: "slideshowcomplete",
        SLIDESHOW_CONTINUE: "slideshowcontinue",
        ZOOM_READY: "zoomLoaded"
    },
    merged:undefined,
	_el: undefined,
	scene: undefined,

	SMOOTHNESS:undefined,
	SPEED_ZOOM:undefined,
	SPEED_PAN:undefined,
   TargetCenterX:undefined,
	TargetCenterY:undefined,
	VALUE:undefined,
	DISTANCE:undefined,
	STEPS:undefined,
	ZOOM:undefined,
	MIN:undefined,
	ZoomAtStart: false,  //run setInitialView true | false
	ZoomIsLoaded: false, //has zoom completed loading?
	ZoomEl: undefined,
    isSlideshow: false,
    timer: undefined,
    timerSeconds: 5000, //5 secons
    isTimerPaused: false,
    slider:undefined,

    __init__: function(defaults) {
		this.merged = defaults.merged || [];
		this._el = document.getElementById(defaults._el) || undefined;
		this.$imagesWrap = defaults.$imagesWrap || undefined;
		this.scene = defaults.scene || {};

		this.SMOOTHNESS=defaults.SMOOTHNESS||10;
		this.SPEED_ZOOM=defaults.SPEED_ZOOM||5.5;
		this.SPEED_PAN=defaults.SPEED_PAN||5.5;
		this.TargetCenterX=parseFloat(defaults.scene.plot1.upperleft.x) || 2200;
		this.TargetCenterY=parseFloat(defaults.scene.plot1.upperleft.y) || 1225;
		this.VALUE=defaults.VALUE || 20;
		this.DISTANCE=defaults.DISTANCE || 100;
		this.STEPS=defaults.STEPS || 7;
		this.ZOOM=defaults.ZOOM || 20;
		this.MIN=defaults.MIN || 20;
		this.ZoomAtStart = true;
        if(this.merged.length > 1){
            this.isSlideshow = true;
        } else {
            this.isSlideshow = false;
        }
            this.images = [];
            var _that = this;
            for(var i=0;i<this.merged.length;i++){
                var img = new Image(); //preload images
                img.onload = function(){
                    //log('preloaded image for caching');
                    //alert('img width' + (this).width + "; height:"+ (this).height );
                    //this.merged[i]['dimensions'] = { width: this.width , height: this.height };
                    //this.merged[i]['is_loaded'] = true;
                    $(this).data('loaded','true');
                    if( this.id == 'preloadimg0' ){
                        log('here we go! Image cached: \n preloadimg0 loaded... loadSliderSettings');
                        _that.loadSliderSettings( $(_that.$imagesWrap).find("img") );
                    }
                }
                //json = $.parseJSON(this.merged[i].images);
                img.id = 'preloadimg'+i;
                img.src = this.merged[i].images.large;

                this.images.push( img )
            }
        var imagesTarget = $(this.$imagesWrap).find("img");
		$(imagesTarget).css("maxWidth","none");        
	},
	bind: function(){
		var _this = this;
        log(' bind all events ');
       //Speed Config:
        $("#sliderSpeed").slider({
            step: 0.5,
            min: 0.5,
            max: 10.0,
            value: 5.5,
            stop: function (event, ui) {
                _this.SPEED_ZOOM = ui.value;
                _this.SPEED_PAN = ui.value;
            }
        });
      //Smoothness Config:
        $("#sliderSmooth").slider({
            step: 0.5,
            min: 0.5,
            max: 10.0,
            value: 10.0,
            stop: function (event, ui) {
                _this.SMOOTHNESS = ui.value;
            }
        });
        if(this.isSlideshow){
                $(this).on(this.Events.SLIDESHOW_CONTINUE,function(evt,obj){
                    log("slideshow: continue");
                    _this.isTimerPaused = false;
                });
                $(this).on(this.Events.SLIDESHOW_PAUSE,function(evt,obj){
                    log("slideshow: paused");
                    _this.isTimerPaused = true;
                });
                $(this).on(this.Events.SLIDESHOW_END,function(evt,obj){
                    log("slideshow: ended");
                    clearInterval(_this.timer);
                });
		          this.TargetCenterX=this.scene.plot1.upperleft.x;
		          this.TargetCenterY=this.scene.plot1.upperleft.y;
        }



	},
	loadSliderSettings: function( img) { //Slider & Zoom
		var _this = this;
        this.ZoomEl = img || this._el;
        var $wrapper = $("#image_area");
        //zoom
        var maxWidth = $(this.ZoomEl).width(); //safari return 0 with width()
        var maxHeight =$(this.ZoomEl).height(); //safari return 0 with height()
        if(maxHeight == 0){
            if( (_this.images[0])&&
                ( $(_this.images[0]).data('loaded')=='true') ){
               maxWidth = _this.images[0].width;
               maxHeight = _this.images[0].height;
            }
            //alert($(_this.images[0]).data('loaded') + ' Safari ! with and height were not set. how about now? ' + _this.images[0].height );
        }
        log('MaxWidth => ' + maxWidth);
        log('MaxHeight => ' + maxHeight);
        log('initial Zoom => ' + this.scene.zoom.zoom);
        this.setTargetCenterXY();
        this.setSliderZoomDistance( this.scene.zoom.zoom );
    //.smoothZoom('destroy').
        //$( this.ZoomEl ).smoothZoom('destroy').smoothZoom({
			$( this.ZoomEl ).smoothZoom({
				initial_ZOOM: this.ZOOM>20?this.ZOOM:"",
                initial_POSITION: this.TargetCenterX + " " +this.TargetCenterY,
            width: maxWidth, //_this.scene.dimensions.width,
            height: maxHeight, //_this.scene.dimensions.height,
            max_WIDTH: maxWidth, //dimensinos apparently saved in database aren't always correct
            max_HEIGHT: maxHeight,
            responsive: true,
            responsive_maintain_ratio: true,
            zoom_BUTTONS_SHOW: false,
            pan_BUTTONS_SHOW: false,
            touch_DRAG: true,
            mouse_DRAG: true,
            mouse_WHEEL: true,
            mouse_WHEEL_CURSOR_POS: true,
            animation_SMOOTHNESS: _this.SMOOTHNESS,
            animation_SPEED_ZOOM: _this.SPEED_ZOOM,
            animation_SPEED_PAN: _this.SPEED_PAN,
            container: "image_area",
            on_IMAGE_LOAD: function(){
                log('zoom tool: image loaded');
                if(_this.isSlideshow){
				   var hidden =  $(".zoomSlideHidden");
					var shown =  $(_this.$imagesWrap).find("img");
						$(shown).addClass("zoomSlideSelected");
                    /**
						for(var i=0;i<hidden.length;i++){
						  var img = hidden[i];
									$(img).insertBefore(shown);
						}
                    
						$(_this).trigger(_this.Events.SLIDESHOW_START);
                    ***/
                }
                //alert('safari its loaded');

            },

            on_ZOOM_PAN_UPDATE: function(){
                log('pan update');
                debug(this);
                if(_this.isSlideshow){
                  $(_this).trigger(_this.Events.SLIDESHOW_PAUSE);
                }
            },
            on_ZOOM_PAN_COMPLETE: function(){
                log('pan complete');
                debug(this);
                if(_this.isSlideshow){
                $(_this).trigger(_this.Events.SLIDESHOW_CONTINUE);
                }
               if(_this.ZoomIsLoaded == false){
                  $(_this).trigger(_this.Events.ZOOM_READY);
				}
				_this.ZoomAtStart = false;
				_this.ZoomIsLoaded = true;
            }
        });

        //Slider:


        $("#slider").slider({
                step: _this.VALUE,
                min: _this.MIN,
                max: (_this.VALUE * _this.STEPS),
                value: _this.VALUE,
                slide: _this.updateSlider
        }).bind({
				started: function(evt,ui){
                    console.log("#slider started");
					$(this).slider( "value", ui.value);
					_this.updateSlider(evt,ui);
				}
			});
        //$('#imgBillboardView').smoothZoom('focusTo', {x:1800, y:1200, zoom:30.0, speed:10}); // 10 fast so use does not see it.
       // $('#billboardview').smoothZoom('getZoomData')
        //$(this.ZoomEl).smoothZoom('focusTo', {x:10, y:10, zoom:30.0, speed:10}); // 10 fast so use does not see it.
        //$('#imgBillboardView').smoothZoom('focusTo', {x:TargetCenterX, y:TargetCenterY, zoom:30.0, speed:10}); // 10 fast so use does not see it.
        //$("#slider").slider( "value", 60);


    }, // End Slider Settings
    nextSlide: function(){
        log('called next '+ oaaaProject.isTimerPaused);
        if(!oaaaProject.isTimerPaused){
                //this.merged[i].images.large
            debug( this );
           var data = $(oaaaProject.ZoomEl).data();
           var src = $(oaaaProject.ZoomEl).attr("src");
            var match = 0;
            $.each( data , function( index, value ) {
                if(value === src){
                    match = parseInt( index.replace("image","") );
                }
            });

            //next
            if(typeof data["image" + (match+1)] !== 'undefined'){
                $(oaaaProject.ZoomEl).attr("src", data["image" + (match+1)] );
            } else { //start over at one
                $(oaaaProject.ZoomEl).attr("src", data["image1"] );
            }


        }
    },
	setInitialView: function() {
        // 10 fast so use does not see it.
          //$( this.ZoomEl ).smoothZoom('focusTo', { x: this.TargetCenterX, y: this.TargetCenterY, zoom:50 });
          //$( this.ZoomEl ).smoothZoom('focusTo', { x: this.TargetCenterX, y: this.TargetCenterY, zoom: 200, speed: 1 });
        /*** NO WAY TO TRIGGER SO MUST MANUAL set **/
        	var v = this.scene.zoom.value;
            $("#slider").slider( "value", v );
        /** 
            //this below breaks smoothzoom 
            $("#slider").trigger("started",{'value': v});
        **/
		
    },
    setTargetCenterXY: function(){
		var scene = this.scene;
		if( scene.plotsTotal == 1){
            log("set target center x,y from plot 1");
			var diffX = parseInt( scene.plot1Dimensions.width / 2 );
			var diffY = parseInt( scene.plot1Dimensions.height / 2 );
			this.TargetCenterX = parseInt(scene.plot1.upperleft.x) + diffX;
			this.TargetCenterY = parseInt(scene.plot1.upperleft.y) + diffY;
		} else {
			//check preloaded images for actual width and height;
            log("set target center x,y based upon scene dimensions");
			var img = this.images[0];
			if( $(img).data('loaded') == "true"){
				var diffX = parseInt( img.width / 2 );
				var diffY = parseInt( img.height / 2 );
				this.TargetCenterX = diffX;
				this.TargetCenterY = diffY;
			}
		}

	},
    setSliderZoomDistance: function(v){
        var _this = (typeof _this !== 'undefined')?_this:oaaaProject;
                    if (v == _this.VALUE * 1)
                    {
                        _this.TargetCenterX += 0;
                        _this.DISTANCE = 100;
                        _this.ZOOM = 20.0;
                    } else if (v == _this.VALUE * 2)
                    {
                        _this.TargetCenterX += 0;
                        _this.DISTANCE = 200;
                        _this.ZOOM = 25.0;
                    } else if (v == _this.VALUE * 3)
                    {
                        _this.TargetCenterX += 0;
                        _this.DISTANCE = 300;
                        _this.ZOOM = 30.0;
                    } else if (v == _this.VALUE * 4)
                    {
                        _this.TargetCenterX += 0;
                        _this.DISTANCE = 400;
                        _this.ZOOM = 37.5;
                    } else if (v == _this.VALUE * 5)
                    {
                        _this.TargetCenterX += 0;
                        _this.DISTANCE = 500;
                        _this.ZOOM = 50.0;
                    } else if (v == _this.VALUE * 6)
                    {
                        _this.TargetCenterX += 0;
                        _this.DISTANCE = 600;
                        _this.ZOOM = 70.0;
                    } else if (v == _this.VALUE * 7)
                    {
                        _this.TargetCenterX += 0;
                        _this.DISTANCE = 700;
                        _this.ZOOM = 85.0;
                    } else {
                        log("no match zoom value ("+v+") to calculate distance and zoom");
                    }  
            log(" TargetCenterX =>" + _this.TargetCenterX);
            log(" TargetCenterY =>" + _this.TargetCenterY);        
            log(" Distance =>" + _this.DISTANCE);
            log(" Zoom => " + _this.ZOOM);
    },
	updateSlider: function (event, ui) {
                    var _this = (typeof _this !== 'undefined')?_this:oaaaProject;
                    log('Slider moved: new slider value => ' + ui.value );
                    log('compare to project value => ' + _this.VALUE);
                    _this.setSliderZoomDistance( ui.value );

                $( _this.ZoomEl ).smoothZoom('focusTo', { x: _this.TargetCenterX, y: _this.TargetCenterY, zoom: _this.ZOOM, speed: _this.SPEED_ZOOM });
                //debug( $(_this.ZoomEl).smoothZoom('getZoomData') );

                //var params = { x: TargetCenterX, y: TargetCenterY, zoom: ZOOM, speed: SPEED_ZOOM };
                //$('#billboardview').smoothZoom('getZoomData');
                if(typeof $(_this.ZoomEl).smoothZoom('getZoomData')  === 'undefined'){
                    _this.loadSliderSettings();
                }
               //debug( params );
                //$( _this.ZoomEl ).smoothZoom('focusTo', params );
                //writeObject(DISTANCE, ZOOM);
      }
});
/********* UTILITIES ******************************/
IRONISTIC_OAAA.ApiProxy = function() {
	this.Events = {
		API_ERROR: 'apiError',
		API_SUCCESS: 'apiSuccess'
	};
	this.Consts = {
		REQUEST_TYPE_GET: 'GET',
		REQUEST_TYPE_POST: 'POST'
	};
	this.baseURL = IRONISTIC_OAAA.Consts.BASE_API_URL;
	var responseModel = undefined;
	this.get = function(url, model, params) {
		this.responseModel = model;
		this.makeRequest(url, this.Consts.REQUEST_TYPE_GET, params);
	}
	this.post = function(url, model, params) {
		this.responseModel = model;
		this.makeRequest(url,this.Consts.REQUEST_TYPE_POST, params);
	}
	this.makeRequest = function(url, type, params) {
		var self = this;
		$.ajax({
			type: type,
			url: self.baseURL + url,
			data: params,
			dataType: 'json',
			error: $.proxy(self.error, self),
			success: $.proxy(self.success, self)
		})
	}
	this.error = function(jqXHR, textStatus, errorThrown) {
		//log(errorThrown)
		$(this).trigger(this.Events.API_ERROR, textStatus);
	}
	this.success = function(resp,textStatus,data) {
		if ((data.statusText.toLowerCase() != 'ok')||(data.requestStatus === 'fault')) {
			$(this).trigger(this.Events.API_ERROR, resp);
			return;
		}
		var model = new this.responseModel(resp);
		$(this).trigger(this.Events.API_SUCCESS, model);
	}
}

IRONISTIC_OAAA.Prompt = {
	msg: function(txt){
		alert(txt);
	}
}

IRONISTIC_OAAA.MyProject = {

	getProjectJson: function(projectName) {
		return this.painterCanvasControls.getProjectJson(projectName);
	}


}

IRONISTIC_OAAA.Util = {

        calcRatioHeight: function (srcWidth, srcHeight, newWidth) {
            log('calculate ratio Height');
            return Math.round((srcHeight / srcWidth) * newWidth); // = new height
        },

        calcRatioHeightPercent: function (srcWidth,newWidth){
            debug('... ratio scrunch is:' + ((newWidth / srcWidth) * 100) + "%");
            return ((newWidth / srcWidth) * 100);
        },

        cloneCanvas: function (oldCanvas) {
            if(!oldCanvas) return;
            //create a new canvas
            var newCanvas = document.createElement('canvas');
            var context = newCanvas.getContext('2d');
            //set dimensions
            newCanvas.width = oldCanvas.width;
            newCanvas.height = oldCanvas.height;
            //apply the old canvas to the new one
            context.drawImage(oldCanvas, 0, 0);
            return newCanvas;
        },

        calcAspectRatio: function( x, y){
            var aspect = x/y;
            if (aspect < 1) aspect = 1/aspect;
            var niceaspect = IRONISTIC_OAAA.Util.getPrettyAspectRatio(aspect);
            var str = (niceaspect != null)?niceaspect:(aspect).toFixed(1);
            return str + ":1";
        },
        getPrettyAspectRatio: function (aspect){
            for (i=1;i<=20;i++)
            {
                if (IRONISTIC_OAAA.Util.is_int((aspect)*i)) {
                    return ""+i.toFixed(0)+":"+(aspect*i).toFixed(0)
                }
            }
            return null;
        },
        is_int: function (value){
                if((parseFloat(value) == parseInt(value)) && !isNaN(value)){
                  return true;
              } else {
                  return false;
              }
        }


}

var oaa;
$(function(){

	//oaa = new IRONISTIC_OAAA.CUSTOM_PROJECT_VIEW();

});

