var billboardFullWidth;//dropzone fullwidth
var billboardDropzone1;
var billboardDropzone;
var billboardLinks;


//validate billboards when ready to save
	function formValidateBillboards(){
        // check if we have new billboards to upload
        var newBillboards = getUploadedBillboards();
        if( $.isEmptyObject(newBillboards) == false){
        //yes new billboards
            myproject.form = $(this);
            var obj = myproject.form;
            $(obj).on(myproject.Events.BILLBOARD_SAVED_SUCCESS,function(evt,model){
                log('success handled');
                debug( arguments );
                log(' save scene id and title since billboards are good to go');
                if(model && model.status == true){
                    document.forms.updateProjectForm.submit();
                } else if (model && model.msg) {
                    alert( model.msg );
                }
            });
            $(obj).on(myproject.Events.BILLBOARD_SAVED_FAIL,function(evt,model){
                log('failed handled');
                debug( arguments );
            });

            saveNewBillboardsToProject(myproject);
        } else if(myproject.billboards.length > 0) {
            //already have billboards
                //alert('billboards already saved and attached');
                document.forms.updateProjectForm.submit();
        } else {
            alert('Please upload an image to the project');
        }
	}

//save Canvas to temporarily Uploaded directory
    function postCanvas(myProject){
		startStatus('proj-status-upload');
                var list = myProject.canvasArrSlideshow.length>0?myProject.canvasArrSlideshow:myProject.canvas;
                var parameters = {
					"files": {},
					"_token": document.forms.uploadCanvas._token.value
				};
				console.time('toDataURL');

				if(list.length >= 1) {
					for(var i=0;i<list.length;i++){
						//parameters.files[i] = myProject.optimizeCanvas(list[i]).toDataURL("image/png");
						parameters.files[i] = (list[i]).toDataURL("image/png");
					}
				} else {
					//parameters.files = myProject.optimizeCanvas(list).toDataURL("image/png");
					parameters.files = (list).toDataURL("image/png",0.2);  // 0.2 decrease image quality for testing purposes
				}
				console.timeEnd('toDataURL');
				//log( $.param(parameters) );
				//log(  decodeURIComponent(  $.param(parameters) ) );


				$.ajax({
						url: document.forms.uploadCanvas.action,
						type: 'POST',
						data: $.param(parameters),
						cache: false,
						dataType: 'json',
						success: function(data, textStatus, jqXHR)
						{
							//alert('success uploaded canvas');
                            debug(data);
				            if( !myProject.mergedArr )
								  myProject.mergedArr = [];


							if(data.merged && data.merged.length > 0){
                                for(var i=0;i<data.merged.length;i++){
                                   var merged = $.parseJSON(data.merged[i]);
                                   if(merged.is_uploaded == true){
                                       myProject.mergedArr.push( merged );

                                   }
                                }
                                saveProject( myProject );
                            }
							else
							{
								// Handle errors here
                                alert('problem handling successful response');
								saveProject( myProject );
							}
						},
						error: function(jqXHR, textStatus, errorThrown)
						{
							// Handle errors here
							log('ERRORS: ' + textStatus);
							// STOP LOADING SPINNER
							debug(arguments);
							//continue to edit
							saveProject( myProject );
						}
					});


        }


//save Billboard To Project After Temporarily Uploaded

    function saveNewBillboardsToProject(myProject){
                var files = getUploadedBillboards();
				var params = {
								'_token': document.forms.saveBillboardToProject._token.value
							};
                var apiSaveProject = new IRONISTIC_OAAA.ApiProxy();
		            $(apiSaveProject).on(apiSaveProject.Events.API_SUCCESS, function(e, model){
		                $(apiSaveProject).off();
                                var project = model;
                                location.href = "/projects/edit/" + project.id;
		                debug(model);
		                //$this.scenes = model;
		                log(' saved successfully.');
                                debug(arguments);
                                $(myProject.form).trigger(myProject.Events.BILLBOARD_SAVED_SUCCESS, project);
		            });
		            $(apiSaveProject).on(apiSaveProject.Events.API_ERROR, function(e, model){
		                $(apiSaveProject).off();
		                log('oops');
                                debug(arguments);

                            if(model && (model.msg == 'No Billboard(s) Saved' || model.msg.indexOf("Saved") > 0) ){  //Image already saved?

                        		location.href = "/project/" + model.billboards[0].project_id;
                        		$(myProject.form).trigger(myProject.Events.BILLBOARD_SAVED_SUCCESS, model);
							} else {
		               			IRONISTIC_OAAA.Prompt.msg( model.msg );
		               			$(myProject.form).trigger(myProject.Events.BILLBOARD_SAVED_FAIL, model);
						    }


		            });


                            if(params.name == ''){
                                alert('Please enter a project title');
                                return;
                            }

		            //apiSaveProject.post("/json/project/"+myProject.projectid+"/billboard",
                   apiSaveProject.baseURL = "";
                   apiSaveProject.post("/projects/save/"+myProject.projectid+"/billboards",IRONISTIC_OAAA.ProjectModel,jQuery.extend(params, files) );

        }

    function showUploadPlots(total){
        var wrap = $("#uploadbillboard");
        $(wrap).attr("class","");
        if(total > 2){
            $("#plotstotal").addClass("oaaa-col-3");
            $(wrap).addClass("plot1 plot2 plot3 noplot4");
        } else if (total > 1){
            $("#plotstotal").addClass("oaaa-col-2");
            $(wrap).addClass("plot1 plot2 noplot3 noplot4");
        } else {
            $(wrap).addClass("plot1 noplot2 noplot3 noplot4");
            $("#fileFullWidth").data("plots",total);
            $("#plotstotal").hide();
        }
        initCreateDropZones(myproject.scene.slideshow);
    }

        function getUploadedBillboards(){
            var boards = {};
                //
                if(typeof billboardFullWidth != 'undefined')
                {
                    for(var i=0;i<billboardFullWidth.files.length;i++){
                        var file = billboardFullWidth.files[i];
                        if(file.accepted == true && file.status == 'success'){
                            var obj = $.parseJSON(file.xhr.response);
                            if(obj.is_uploaded){
                                boards[ "0_image_"+i ] = obj.filename;
                            }
                        }
                    }
                }
                //file1
               if(typeof billboardDropzone1 != 'undefined')
                {
                    for(var i=0;i<billboardDropzone1.files.length;i++){
                        var file = billboardDropzone1.files[i];
                        if(file.accepted == true && file.status == 'success'){
                            var obj = $.parseJSON(file.xhr.response);
                            if(obj.is_uploaded){
                                boards[ "0_image_"+i ] = obj.filename;
                            }
                        }
                    }
                }
                //file2
               if(typeof billboardDropzone2 != 'undefined')
                {
                    for(var i=0;i<billboardDropzone2.files.length;i++){
                        var file = billboardDropzone2.files[i];
                        if(file.accepted == true && file.status == 'success'){
                            var obj = $.parseJSON(file.xhr.response);
                            if(obj.is_uploaded){
                                boards[ "1_image_"+i ] = obj.filename;
                            }
                        }
                    }
                }
                //file3
               if(typeof billboardDropzone3 != 'undefined')
                {
                    for(var i=0;i<billboardDropzone3.files.length;i++){
                        var file = billboardDropzone3.files[i];
                        if(file.accepted == true && file.status == 'success'){
                            var obj = $.parseJSON(file.xhr.response);
                            if(obj.is_uploaded){
                                boards[ "2_image_"+i ] = obj.filename;
                            }
                        }
                    }
                }
                return boards;
    }
/** UPDATE PROJECT PAGE ***/
	function ajaxDeleteBillboard( event ) {
                            $.ajax({
                                type: "DELETE",
                                url: document.forms.deleteBillboard.action +  $(event).data('id'),
                                //data: $(document.forms.deleteBillboard).serialize(),
                                dataType: "json",
                                contentType: "application/json",
                                beforeSend: function(xhr, settings){
                                        xhr.setRequestHeader("X-CSRF-Token", document.forms.deleteBillboard._token.value);
                                },
                                complete: function(xhr,str){
                                    console.dir(arguments);
                                    debug(xhr);
                                    var errorMessage = "Severe Problem with the project; unable to delete billboard image";

                                    var json = (xhr.responseJSON)?xhr.responseJSON:$.parseJSON( xhr.responseText );
                                    if(xhr.status == 200 || str == 'success'){
                                        if(json){

                                            if(json.status){
                                                if(json.billboards.length == 0){
                                                    location.reload();
                                                } else {
                                                    //alert ( 'display returned billboards ');
                                                    for(var i=0;i<myproject.billboards.length;i++){
														//turn off onclick;
														console.warn('turn off onclicks');
													}
                                                    myproject.billboards = json.billboards;
                                                    //lets save merged file now instead of showing billboards
                                                    //showProjectBillboards(myproject.billboards);
                                                    $("#save").trigger("click");
                                                    return;
                                                }

                                            }
                                            alert(json.msg);
                                            location.reload();
                                            return;
                                        }
                                    }
                                    alert(errorMessage);

                                }
                            });
	}
	function checkForExtensionForm(allbillboards){

		var hasExtensionBillboard = false;
		for(var i=0;i<allbillboards.length;i++){
			var billboard = allbillboards[i];
			if(billboard.extension != 0){
					hasExtensionBillboard = true;
			}
		}
		return hasExtensionBillboard;
	}

    function showProjectBillboard(element,billboard){
                var a = $("<a/>").attr("href","#delete-billboard").attr("onclick","return false;").data('id',billboard.id);
                var div = document.createElement("div");
                var img = $("<img/>").attr("src", IRONISTIC_OAAA.Consts.IMAGES_BILLBOARDS_PREVIEW + billboard.image);
                $(img).appendTo(a);
                $(a).append("<span>Delete</span>");
                $(a).appendTo(div);

                $(div).attr("class","oaaa-block--img").css("display","inline-block");
                billboardLinks.push( { "link": a, "billboard":billboard } );
                $(div).on("click","a",function(evt){
                    evt.preventDefault;
                        if( window.confirm("Are you sure you want to delete?") ){

							ajaxDeleteBillboard( this );

                        }
                });
                $(element).append(div); //dropzones
    }

	function showProjectBillboards(allbillboards){
		var el = $("#fileFullWidth");
        var el1 = $("#file1");
        var el2 = $("#file2");
        var el3 = $("#file3");
		if( allbillboards && allbillboards.length > 0 ){
			$(el).html(""); //clean up if needed

		}

        for(var i=0;i<allbillboards.length;i++){
            var billboard = allbillboards[i];

                if(billboard.plot == 0){
                    showProjectBillboard(el,billboard); //filefullwidth
                        showProjectBillboard(el1,billboard);
                } else if (billboard.plot == 1){
                    //$(el2).append(div)
                    showProjectBillboard(el2,billboard);
                } else if (billboard.plot == 2){
                    //$(el3).append(div);
                    showProjectBillboard(el3,billboard);
                }

        }

	}
    function extendExpiration(){
    	var dateInput = $("#projectExpires");
		var newDate = $(dateInput).data('date30');
		$( dateInput ).val( newDate );
		$("#projectExpiresTxt").html( newDate );
    }

    function showScene(obj){
        //remove selected
        var clss ="selected";
        $("#scenesThumbnails").find("a").each(function(){
            if( $(this).hasClass(  clss ) ){
                $(this).removeClass( clss )
            }
        });
        $(obj).addClass( clss );

        var scene = $(obj).data();
        setPreview(scene);
    }

    function setPreview(sceneData){
		var scene = myproject.getSceneById( sceneData.id );
        $("#formatLabel").html( scene.title );

        $("#scenePreview").attr('src',sceneData.images.preview);

		var reqImg = $("#sceneDimensions");
		$(reqImg).html("");
        if(scene.plot1Dimensions){
            $("<span>Bulletin "+scene.plot1Dimensions.width+"px x "+ scene.plot1Dimensions.height+"px</span>").appendTo( reqImg );
        }
        if(scene.plot2Dimensions){
            $("<span>Bulletin (2) "+scene.plot2Dimensions.width+"px x "+ scene.plot2Dimensions.height+"px</span>").appendTo( reqImg );
        }
        if(scene.plot3Dimensions){
            $("<span>Bulletin (3) "+scene.plot3Dimensions.width+"px x "+ scene.plot3Dimensions.height+"px</span>").appendTo( reqImg );
        }
        debug(scene);
        var aspect_ratio = (scene.aspect_ratio != "")?scene.aspect_ratio:IRONISTIC_OAAA.Util.calcAspectRatio( scene.plot1Dimensions.width, scene.plot1Dimensions.height );
        $("<div>Aspect Ratio: "+aspect_ratio+"</div>").appendTo( reqImg );

        $("#sceneid").val( scene.id );

    }

/** CREATE PROJECT PAGE ***/
    function saveProject(myproject){
		//startStatus("proj-status-optimize"); //turn on
                var files = getUploadedBillboards();
                var list = myproject.canvasList  || myproject.canvas; //merged
                var apiSaveProject = new IRONISTIC_OAAA.ApiProxy();
		            $(apiSaveProject).on(apiSaveProject.Events.API_SUCCESS, function(e, model){
		                $(apiSaveProject).off();
		                $(myproject).trigger(myproject.Events.BILLBOARD_SAVE_SUCCESS,model);
                                var project = model;
                                $(myproject).unbind();
                                location.href = "/projects/edit/" + project.id;
		                //debug(model);
		                //$this.scenes = model;
		                //log(' saved successfully.');
                                //debug(arguments);
                                //alert('project created success');

		            });
		            $(apiSaveProject).on(apiSaveProject.Events.API_ERROR, function(e, model){
		                $(apiSaveProject).off();
						$(myproject).trigger(myproject.Events.BILLBOARD_SAVE_FAIL,model);
                                var project = model;

                                if(project.id) {
                                        $(myproject).unbind();
                                        location.href = "/projects/edit/" + project.id;
                                } else {
                                        IRONISTIC_OAAA.Prompt.msg( e );
                                }
		            });
		            var params = {
		                '_token': document.forms.uploadForm1._token.value,
                                'name' : $('#projectTitle').val(),
                                'expires' : $('#projectExpires').val(),
                                'scene_id': myproject.scene.id,
                                merged: myproject.mergedArr
		            }
		            /** upload merged scene ***/
		            /***
					if(list.length >= 1) {
								for(var i=0;i<list.length;i++){
									params.merged[i] = myproject.optimizeCanvas(list[i]).toDataURL("image/png");
								}
					} else {
								  params.merged = myproject.optimizeCanvas(list).toDataURL("image/png");
					}
					****/



                            if(params.name == ''){
                                alert('Please enter a project title');
                                return;
                            }
                debug( jQuery.extend(params, files) );
				apiSaveProject.baseURL = "";
		            apiSaveProject.post(document.forms.createNewProject.action, IRONISTIC_OAAA.ProjectModel,jQuery.extend(params, files) );

        }

 function initCreateDropZones(isMultipleUploadEnabled){
	log( 'initCreateDropZones called');
	billboardFullWidth = new Dropzone("#fileFullWidth",{
            paramName: "files",
            url: document.forms.uploadForm1.action,
            autoProcessQueue: true, //turn off auto upload
            params: { '_token': document.forms.uploadForm1._token.value },
            addRemoveLinks: true,
            thumbnailWidth: null,
            thumbnailHeight: null,
            maxFiles: (myproject.scene.slideshow ==true)?20:1 ,
            dictDefaultMessage: 'Drag and Drop Images Here',
            init: function(){
                        //debug(arguments);
                        //debug(this);
                        if( myproject.scene.slideshow ==true ){
    						$(this.element).html('<div class="dz-message" data-dz-message><span>Drag and Drop Multiple Images for slideshow</span></div>');
						} else {
                            $(this.element).html('<div class="dz-message" data-dz-message><span>Drag and Drop Image 1</span></div>');
						}
                    this.on("addedfile",function(file){
                            //console.log("file added")
                            var progressElement = file.previewElement.querySelector(".dz-progress > span");
                            $( progressElement ).css("width", "1%" );
                            //console.log("preview element updated");
                            var progressTemp = file.previewTemplate.querySelector(".dz-progress > span");
                            $( progressTemp ).css("width", "1%" );
                            //console.log("preview template updated");
                    });
            },
            uploadprogress: function(file, progress, bytesSent) {
                //console.dir(arguments);
                //console.dir(this);

                if (myproject.scene.slideshow == false) {
                    var progressElement = this.element.querySelector(".dz-progress > span");
                    progressElement.style.width = progress + "%";

                            $( progressElement ).attr("data-progress", progress );
                            $( progressElement ).css("width", progressElement.style.width );
                            $( progressElement ).attr("title","...uploading " + progress );
                        if(progress == 100){
                            $( progressElement ).css({"background-image":"none","background-color":"green"});
                        }
               } else {
                    var progressElement = file.previewElement.querySelector(".dz-progress > span");
                    progressElement.style.width = progress + "%";
                            $( progressElement ).attr("data-progress", progress );
                            $( progressElement ).css("width", progressElement.style.width );
                            $( progressElement ).attr("title","...uploading " + progress );
                        if(progress == 100){
                            $( progressElement ).css({"background-image":"none","background-color":"green"});
                        }
               }
            },
            success: function(){
                //alert('success');
                $(this.element).find(".dz-message").hide();
            },
            error: function(file,msg){
                //log('error');
                alert('Error:'+msg);
            },
            maxfilesexceeded: function(file){
                alert('Maximum image uploads have been exceed');
                $(file._removeLink).trigger("click");
                //debug( file._removeLink );
                $(file.previewElement).remove();
            },
            removedFile: function(){
               // log('remove file');
            }
    });


    billboardDropzone1 = new Dropzone("#file1",{
          paramName: "files",
            url: document.forms.uploadForm1.action,
            autoProcessQueue: true, //turn off auto upload
            params: { '_token': document.forms.uploadForm1._token.value },
            addRemoveLinks: true,
            thumbnailWidth: null,
            thumbnailHeight: null,
            maxFiles: 1,
            dictDefaultMessage: 'Drag and Drop Images Here',
            init: function(){
                        //debug(arguments);
                        //debug(this);
                        if( myproject.scene.slideshow ==true ){
    						$(this.element).html('<div class="dz-message" data-dz-message><span>Drag and Drop Multiple Images for slideshow</span></div>');
						} else {
                            $(this.element).html('<div class="dz-message" data-dz-message><span>Drag and Drop Image 1</span></div>');
						}
                    this.on("addedfile",function(file){
                            //console.log("file added")
                            var progressElement = file.previewElement.querySelector(".dz-progress > span");
                            $( progressElement ).css("width", "1%" );
                            //console.log("preview element updated");
                            var progressTemp = file.previewTemplate.querySelector(".dz-progress > span");
                            $( progressTemp ).css("width", "1%" );
                            //console.log("preview template updated");
                    });
            },
            success: function(){
                //alert('success');
                $(this.element).find(".dz-message").hide();
            },
            error: function(file,msg){
                //log('error');
                alert('Error:'+msg);
            },

            uploadprogress: function(file, progress, bytesSent) {
                //console.dir(arguments);
                //console.dir(this);

                if (myproject.scene.slideshow == false) {
                    var progressElement = this.element.querySelector(".dz-progress > span");
                    progressElement.style.width = progress + "%";
                   // progressElement.querySelector(".progress-text").textContent = progress + "%";
                 //  console.log( progressElement.style.width );
                  // console.log( progress );
                            $( progressElement ).attr("data-progress", progress );
                            $( progressElement ).css("width", progressElement.style.width );
                            $( progressElement ).attr("title","...uploading " + progress );
                        if(progress == 100){
                            $( progressElement ).css({"background-image":"none","background-color":"green"});
                        }
               }
            },
            maxfilesexceeded: function(file){
                alert('Maximum image uploads have been exceed');
                $(file._removeLink).trigger("click");
                //debug( file._removeLink );
                $(file.previewElement).remove();
            },
            removedFile: function(){
               // log('remove file');
            }
        });

    billboardDropzone2 = new Dropzone("#file2",{
          paramName: "files",
            url: document.forms.uploadForm1.action,
            autoProcessQueue: true, //turn off auto upload
            params: { '_token': document.forms.uploadForm1._token.value },
            addRemoveLinks: true,
            thumbnailWidth: null,
            thumbnailHeight: null,
            maxFiles: 1,
            dictDefaultMessage: 'Drag and Drop Images Here',
            init: function(){
                        //debug(arguments);
                        //debug(this);
                        if( myproject.scene.slideshow ==true ){
    						$(this.element).html('<div class="dz-message" data-dz-message><span>Drag and Drop Multiple Images for slideshow</span></div>');
						} else {
                            $(this.element).html('<div class="dz-message" data-dz-message><span>Drag and Drop Image 2</span></div>');
						}
                    this.on("addedfile",function(file){
                            //console.log("file added")
                            var progressElement = file.previewElement.querySelector(".dz-progress > span");
                            $( progressElement ).css("width", "1%" );
                            //console.log("preview element updated");
                            var progressTemp = file.previewTemplate.querySelector(".dz-progress > span");
                            $( progressTemp ).css("width", "1%" );
                            //console.log("preview template updated");
                    });
            },
            success: function(){
                //alert('success');
                $(this.element).find(".dz-message").hide();
            },
            error: function(file,msg){
                //log('error');
                alert('Error:'+msg);
            },
            uploadprogress: function(file, progress, bytesSent) {
                //console.dir(arguments);
                //console.dir(this);

                if (myproject.scene.slideshow == false) {
                    var progressElement = this.element.querySelector(".dz-progress > span");
                    progressElement.style.width = progress + "%";
                   // progressElement.querySelector(".progress-text").textContent = progress + "%";
                 //  console.log( progressElement.style.width );
                  // console.log( progress );
                            $( progressElement ).attr("data-progress", progress );
                            $( progressElement ).css("width", progressElement.style.width );
                            $( progressElement ).attr("title","...uploading " + progress );
                        if(progress == 100){
                            $( progressElement ).css({"background-image":"none","background-color":"green"});
                        }
               }
            },
            maxfilesexceeded: function(file){
                alert('Maximum image uploads have been exceed');
                $(file._removeLink).trigger("click");
                //debug( file._removeLink );
                $(file.previewElement).remove();
            },
            removedFile: function(){
               // log('remove file');
            }
        });

    billboardDropzone3 = new Dropzone("#file3",{
          paramName: "files",
            url: document.forms.uploadForm1.action,
            autoProcessQueue: true, //turn off auto upload
            params: { '_token': document.forms.uploadForm1._token.value },
            addRemoveLinks: true,
            thumbnailWidth: null,
            thumbnailHeight: null,
            maxFiles: 1,
            dictDefaultMessage: 'Drag and Drop Images Here',
            init: function(){
                        //debug(arguments);
                        //debug(this);
                        if( myproject.scene.slideshow ==true ){
    						$(this.element).html('<div class="dz-message" data-dz-message><span>Drag and Drop Multiple Images for slideshow</span></div>');
						} else {
                            $(this.element).html('<div class="dz-message" data-dz-message><span>Drag and Drop Image 3</span></div>');
						}
                    this.on("addedfile",function(file){
                            //console.log("file added")
                            var progressElement = file.previewElement.querySelector(".dz-progress > span");
                            $( progressElement ).css("width", "1%" );
                            //console.log("preview element updated");
                            var progressTemp = file.previewTemplate.querySelector(".dz-progress > span");
                            $( progressTemp ).css("width", "1%" );
                            //console.log("preview template updated");
                    });
            },
            success: function(){
                //alert('success');
                $(this.element).find(".dz-message").hide();
            },
            error: function(file,msg){
                //log('error');
                alert('Error:'+msg);
            },
            uploadprogress: function(file, progress, bytesSent) {
                //console.dir(arguments);
                //console.dir(this);

                if (myproject.scene.slideshow == false) {
                    var progressElement = this.element.querySelector(".dz-progress > span");
                    progressElement.style.width = progress + "%";
                   // progressElement.querySelector(".progress-text").textContent = progress + "%";
                 //  console.log( progressElement.style.width );
                  // console.log( progress );
                            $( progressElement ).attr("data-progress", progress );
                            $( progressElement ).css("width", progressElement.style.width );
                            $( progressElement ).attr("title","...uploading " + progress );
                        if(progress == 100){
                            $( progressElement ).css({"background-image":"none","background-color":"green"});
                        }
               }
            },
            maxfilesexceeded: function(file){
                alert('Maximum image uploads have been exceed');
                $(file._removeLink).trigger("click");
                //debug( file._removeLink );
                $(file.previewElement).remove();
            },
            removedFile: function(){
               // log('remove file');
            }
        });

}




/*** steps ***/
var status = true;
var statusKeys = [];
function changeStatus(id){
   log("mark complete status " + id);
   if(id.indexOf('upload-longer')>=0) {
	status = false;
	$("#thiswilltakesecond").show();
	var o = $(statusKeys['proj-status-upload'].el);
	$(o).removeClass("fa-check-square");
	if(! $(o).hasClass('fa-spin') ){
		 $(o).addClass("fa-spinner").addClass("fa-spin");
	}
	return;
   }
   if(id.indexOf("proj-status-optimize")>=0){
	var el  = $("#project-status").find("li i.fa-spinner");
	$(el).addClass("fa-check-square"); //upload
	$("#project-status").find("li i").removeClass("fa-spin");
	$("#project-status").find("li i").removeClass("fa-spinner");
 	status = 'true';
   }

   if(status) {
     var obj = statusKeys[id + ''];
	$(obj.el).removeClass("fa-spinner").removeClass("fa-spin").addClass("fa-check-square");
	$(obj.el).removeClass("fa-spin");
        if(obj.next !== ''){
		  window.setTimeout( changeStatus( obj.next ), obj.seconds + 1000);
	}
   }
   console.timeEnd(id);
}

function startStatus(id){
  log("start status ");
  if(statusKeys.length == 0) {
   statusKeys['proj-status-validate'] = {  el: document.getElementById("proj-status-validate"), 'seconds':5 , 'next':'','prev':'' };
   statusKeys['proj-status-upload'] = {  el: document.getElementById("proj-status-upload"), 'seconds':1280, 'next':'proj-status-upload-longer','prev':'' };
   statusKeys['proj-status-optimize'] = {  el: document.getElementById("proj-status-optimize"), 'seconds':120, 'next':'','prev':'proj-status-upload' };
   statusKeys['proj-status-complete'] = {  el: document.getElementById("proj-status-complete"), 'seconds':2, 'next':'' };
  }
  $("#project-status").show();
  var obj = statusKeys['proj-status-validate'];
  if(!id){
   $("#project-status").find("li i").removeClass("fa-check-square").removeClass("fa-spin").removeClass("fa-spinner");
   $("#saveProject").val("....saving...");
   console.time('proj-status-validate');
   window.setTimeout( changeStatus("proj-status-validate"), 10000);
  } else {
	obj = statusKeys[id + ''];
	$(obj.el).addClass("fa-spinner").addClass("fa-spin");
          console.time(obj.id + "  settimeout to " + (obj.seconds + 1000));
	  window.setTimeout( changeStatus( id ), obj.seconds + 1000);
  }

}